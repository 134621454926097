import React from 'react';
var GoogleAd = function (_a) {
    var adSlot = _a.adSlot;
    return (React.createElement("div", null,
        React.createElement("ins", { className: "adsbygoogle", style: { display: 'block', textAlign: 'center', marginBottom: '20px' }, "data-ad-client": "YOUR_ADSENSE_CLIENT_ID", "data-ad-slot": adSlot, "data-ad-format": "auto" }),
        React.createElement("script", null,
            "(adsbygoogle = window.adsbygoogle || []).push(",
            ");")));
};
export default GoogleAd;
