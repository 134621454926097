import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Typography } from '@mui/material';
import { useApp } from '../../../hooks/AppContext';
import './Skills.css';
var Skills = function () {
    var translate = useApp().translate;
    var logos = [
        { src: '../../img/java.png' },
        { src: '../../img/node.png' },
        { src: '../../img/python.png' },
        { src: '../../img/javascript.png' },
        { src: '../../img/react.png' },
        { src: '../../img/angular.png' },
        { src: '../../img/webpack.png' },
        { src: '../../img/typescript.png' },
        { src: '../../img/redux.png' },
        { src: '../../img/jest.png' },
        { src: '../../img/selenium.png' },
        { src: '../../img/cucumber.png' }
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { className: "my-2" },
            React.createElement(Typography, { variant: "h2", className: 'skills-title', dangerouslySetInnerHTML: { __html: translate('skills') }, sx: { textAlign: 'center' }, gutterBottom: true })),
        React.createElement(Row, { className: "text-center my-2" }, logos.map(function (logo, index) { return (React.createElement(Col, { xs: 4, md: 3, key: index, className: "d-flex justify-content-center align-items-center mb-4" },
            React.createElement("img", { src: logo.src, className: "skill-img" }))); }))));
};
export default Skills;
