import React from 'react';
import { useApp } from '../../../hooks/AppContext';
import { Typography, Button } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import EscalatorIcon from '@mui/icons-material/Escalator';
import SecurityIcon from '@mui/icons-material/Security';
import WorkIcon from '@mui/icons-material/Work';
import './Hero.css';
import Logo from '../../../components/logo/Logo';
import { Row, Col } from 'react-bootstrap';
var Hero = function () {
    var translate = useApp().translate;
    return (React.createElement(React.Fragment, null,
        React.createElement(Row, { className: "hero-section text-light", style: {
                backgroundColor: 'black',
            } },
            React.createElement(LogoHero, null),
            React.createElement(Description, { translate: translate }))));
};
var Description = function (_a) {
    var translate = _a.translate;
    var changeView = useApp().changeView;
    return (React.createElement(Row, null,
        React.createElement(Col, { md: 2 }),
        React.createElement(Col, { md: 8 },
            React.createElement(Typography, { className: 'fine-text', dangerouslySetInnerHTML: { __html: translate('description') } }),
            React.createElement(Typography, { className: 'fine-text', dangerouslySetInnerHTML: { __html: translate('standards') } }),
            React.createElement("div", { className: 'standard-list-wrapper' },
                React.createElement(List, { className: 'standard-list', sx: { width: '100%', maxWidth: 360, } },
                    React.createElement(Element, { label: translate('highPerformance'), icon: React.createElement(WhatshotIcon, { sx: { color: 'blue' } }) }),
                    React.createElement(Element, { label: translate('scalable'), icon: React.createElement(EscalatorIcon, { sx: { color: 'blue' } }) }),
                    React.createElement(Element, { label: translate('security'), icon: React.createElement(SecurityIcon, { sx: { color: 'blue' } }) }),
                    React.createElement(Element, { label: translate('tdd'), icon: React.createElement(WorkIcon, { sx: { color: 'blue' } }) }))),
            React.createElement("div", { className: 'wrap-more-about-me-btn' },
                React.createElement(Button, { variant: "contained", onClick: function () { changeView('about'); } }, translate('moreAboutMe')))),
        React.createElement(Col, { md: 2 })));
};
var LogoHero = function () {
    return (React.createElement(Row, null,
        React.createElement(Col, { md: 2 }),
        React.createElement(Col, { md: 8 },
            React.createElement(Logo, { className: 'logo-hero' })),
        React.createElement(Col, { md: 2 })));
};
var Element = function (_a) {
    var label = _a.label, icon = _a.icon;
    return (React.createElement(ListItem, null,
        React.createElement(ListItemAvatar, null,
            React.createElement(Avatar, null, icon)),
        React.createElement(ListItemText, { primary: label })));
};
export default Hero;
