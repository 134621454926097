import React from 'react';
import { Container } from 'react-bootstrap';
import NavBar from '../../components/navBar/NavBar';
import Footer from '../../components/footer/Footer';
import Hero from './hero/Hero';
import Skills from './skills/Skills';
import 'bootstrap/dist/css/bootstrap.min.css';
var Home = function () {
    return (React.createElement(Container, { fluid: true, className: "" },
        React.createElement(NavBar, null),
        React.createElement(Hero, null),
        React.createElement(Skills, null),
        React.createElement(Footer, null)));
};
export default Home;
