import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useApp } from '../../../hooks/AppContext';
import { languageSelectorStyles } from './styles/languageSelector.styles';
var LanguageSelector = function () {
    var _a = useApp(), currentLanguage = _a.currentLanguage, changeLanguage = _a.changeLanguage, translate = _a.translate;
    return (React.createElement(FormControl, { size: "small", sx: languageSelectorStyles.formControl },
        React.createElement(InputLabel, null, translate('languageLabel')),
        React.createElement(Select, { value: currentLanguage, label: "Idioma", onChange: function (e) { return changeLanguage(e.target.value); }, MenuProps: {
                PaperProps: {
                    sx: languageSelectorStyles.menuPaper
                }
            } },
            React.createElement(MenuItem, { value: "en" }, "\uD83C\uDDEC\uD83C\uDDE7English"),
            React.createElement(MenuItem, { value: "es" }, "\uD83C\uDDEA\uD83C\uDDF8Espa\u00F1ol"),
            React.createElement(MenuItem, { value: "fr" }, "\uD83C\uDDEB\uD83C\uDDF7Fran\u00E7ais"))));
};
export default LanguageSelector;
