import React from 'react';
import { Row } from 'react-bootstrap';
import { useApp } from '../../hooks/AppContext';
import Logo from '../logo/Logo';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import { Button } from '@mui/material';
import './Footer.css';
var Footer = function () {
    var _a = useApp(), translate = _a.translate, changeView = _a.changeView;
    return (React.createElement(Row, { className: "footer" },
        React.createElement("div", { className: "footer-content" },
            React.createElement("div", { className: "footer-logo-wrapper" },
                React.createElement(Logo, { className: 'footer-logo' })),
            React.createElement("div", { className: "footer-links" },
                React.createElement(Button, { onClick: function () { return changeView(''); }, color: "inherit", style: { textTransform: 'none', color: 'white' } }, translate('home')),
                React.createElement(Button, { onClick: function () { return changeView('about'); }, color: "inherit", style: { textTransform: 'none', color: 'white' } }, translate('moreAboutMe')),
                React.createElement(Button, { onClick: function () { return changeView('news'); }, color: "inherit", style: { textTransform: 'none', color: 'white' } }, translate('navBar.news"'))),
            React.createElement("div", { className: "footer-social" },
                React.createElement("a", { href: "https://www.linkedin.com/in/manuel-david-garcia-mateos-ba5b11109/", target: "_blank", "aria-label": "LinkedIn" },
                    React.createElement(LinkedInIcon, null),
                    "LinkedIn"),
                React.createElement("a", { href: "https://github.com/kumanmc", target: "_blank", "aria-label": "GitHub" },
                    React.createElement(GitHubIcon, null),
                    "Github"))),
        React.createElement("div", { className: "footer-bottom" },
            React.createElement("p", null, translate('copyright')))));
};
export default Footer;
