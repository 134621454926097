import React from 'react';
import { Row, Col } from 'react-bootstrap';
import LanguageSelector from './lang/LanguageSelector';
import { useApp } from '../../hooks/AppContext';
import { Button } from '@mui/material';
var NavBar = function () {
    var _a = useApp(), changeView = _a.changeView, translate = _a.translate;
    return (React.createElement(Row, { className: "", style: {
            zIndex: 1000,
            backgroundColor: '#0a0a0a',
            padding: '10px',
            borderTop: '5px solid blue',
        } },
        React.createElement(Col, { xs: 7, md: 10 },
            React.createElement(Button, { onClick: function () { return changeView(''); }, color: "inherit", style: { textTransform: 'none', color: 'white' } }, translate('home')),
            React.createElement(Button, { onClick: function () { return changeView('about'); }, color: "inherit", style: { textTransform: 'none', color: 'white' } }, translate('moreAboutMe')),
            React.createElement(Button, { onClick: function () { return changeView('news'); }, color: "inherit", style: { textTransform: 'none', color: 'white' } }, translate('navBar.news'))),
        React.createElement(Col, { xs: 5, md: 2 },
            React.createElement(LanguageSelector, null))));
};
export default NavBar;
