export var languageSelectorStyles = {
    formControl: {
        minWidth: 120,
        '& .MuiInputLabel-root': {
            color: 'white',
            '&.Mui-focused': {
                color: 'white',
            }
        },
        '& .MuiOutlinedInput-root': {
            color: 'white',
            '& fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.5)',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
            }
        },
        '& .MuiSelect-icon': {
            color: 'white',
        }
    },
    menuPaper: {
        bgcolor: 'black',
        '& .MuiMenuItem-root': {
            color: 'white',
            '&:hover': {
                bgcolor: 'rgba(255, 255, 255, 0.1)',
            },
            '&.Mui-selected': {
                bgcolor: 'rgba(255, 255, 255, 0.2)',
            }
        }
    }
};
