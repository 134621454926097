import React from 'react';
import CryptoNews from './CryptoNews';
// import FootballNews from './FootbalNews';
import GoogleAd from './GoogleAd';
import NavBar from '../../components/navBar/NavBar';
import Footer from '../../components/footer/Footer';
import { useApp } from '../../hooks/AppContext';
import { Typography, Box, Container } from '@mui/material';
var News = function () {
    var translate = useApp().translate;
    return (React.createElement(Box, { sx: { backgroundColor: '#f9f9f9', minHeight: '100vh' } },
        React.createElement(NavBar, null),
        React.createElement(Box, { sx: {
                backgroundColor: '#1976d2',
                color: 'white',
                padding: '20px 0',
                textAlign: 'center',
                marginBottom: '30px',
            } },
            React.createElement(Typography, { variant: "h3", component: "h1", sx: { fontWeight: 'bold' } }, translate('news.title')),
            React.createElement(Typography, { variant: "subtitle1", sx: { marginTop: '10px', fontSize: '1.2rem' } }, translate('news.subtitle'))),
        React.createElement(Container, { maxWidth: "lg" },
            React.createElement(Box, { sx: { marginBottom: '30px', textAlign: 'center' } },
                React.createElement(GoogleAd, { adSlot: "header" })),
            React.createElement(CryptoNews, null),
            React.createElement(Box, { sx: { marginTop: '30px', textAlign: 'center' } },
                React.createElement(GoogleAd, { adSlot: "footer" }))),
        React.createElement(Footer, null)));
};
export default News;
