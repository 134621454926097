// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-hero {
  max-width: 30%;
  margin-bottom: 20px !important;
  /* Ajusta este valor para que se vea bien en escritorio */
}

@media (min-width: 768px) {

  /* Cambia 768px al tamaño que prefieras para activar el ajuste */
  .logo-hero {
    max-width: 15%;
    /* Ajusta este valor para que se vea bien en escritorio */
  }
}

.hero-section {
  text-align: center;
}

.fine-text {
  font-weight: 100 !important;
  font-size: 1.1rem !important;
  margin-bottom: 20px !important;
}

.fine-text strong {
  font-weight: 700 !important;
}

.standard-list-wrapper {
  display: flex;
  justify-content: center
}

.standard-list {
  list-style: none;
  /* Opcional: quita los puntos de la lista */
  padding-left: 0;
  /* Quita el padding predeterminado */
  text-align: left;
}

.wrap-more-about-me-btn {
  text-align: center;
  margin-top: 40px !important;
  margin-bottom: 70px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/hero/Hero.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,8BAA8B;EAC9B,yDAAyD;AAC3D;;AAEA;;EAEE,gEAAgE;EAChE;IACE,cAAc;IACd,yDAAyD;EAC3D;AACF;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;EAC3B,4BAA4B;EAC5B,8BAA8B;AAChC;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb;AACF;;AAEA;EACE,gBAAgB;EAChB,2CAA2C;EAC3C,eAAe;EACf,oCAAoC;EACpC,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,2BAA2B;EAC3B,8BAA8B;AAChC","sourcesContent":[".logo-hero {\n  max-width: 30%;\n  margin-bottom: 20px !important;\n  /* Ajusta este valor para que se vea bien en escritorio */\n}\n\n@media (min-width: 768px) {\n\n  /* Cambia 768px al tamaño que prefieras para activar el ajuste */\n  .logo-hero {\n    max-width: 15%;\n    /* Ajusta este valor para que se vea bien en escritorio */\n  }\n}\n\n.hero-section {\n  text-align: center;\n}\n\n.fine-text {\n  font-weight: 100 !important;\n  font-size: 1.1rem !important;\n  margin-bottom: 20px !important;\n}\n\n.fine-text strong {\n  font-weight: 700 !important;\n}\n\n.standard-list-wrapper {\n  display: flex;\n  justify-content: center\n}\n\n.standard-list {\n  list-style: none;\n  /* Opcional: quita los puntos de la lista */\n  padding-left: 0;\n  /* Quita el padding predeterminado */\n  text-align: left;\n}\n\n.wrap-more-about-me-btn {\n  text-align: center;\n  margin-top: 40px !important;\n  margin-bottom: 70px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
