var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { Card, CardMedia, CardContent, Typography, Grid, CircularProgress, Box, Link, } from '@mui/material';
import { useApp } from '../../hooks/AppContext';
// Componente para mostrar anuncios de Google
var GoogleAd = function (_a) {
    var adSlot = _a.adSlot;
    return (React.createElement(Box, { sx: { margin: '20px 0', textAlign: 'center' } },
        React.createElement("ins", { className: "adsbygoogle", style: { display: 'block' }, "data-ad-client": "YOUR_ADSENSE_CLIENT_ID", "data-ad-slot": adSlot, "data-ad-format": "auto" }),
        React.createElement("script", null,
            "(adsbygoogle = window.adsbygoogle || []).push(",
            ");")));
};
var CryptoNews = function () {
    var _a = useState([]), news = _a[0], setNews = _a[1];
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var _c = useApp(), currentLanguage = _c.currentLanguage, translate = _c.translate;
    useEffect(function () {
        console.log('------------------');
        console.log('------------------');
        console.log('------------------');
        console.log('------------------');
        // console.log(process.env);
        // const cryptoApiKey = process.env.REACT_APP_CRYPTO_API_KEY;
        var cryptoApiKey = '63321040a03f6054d5689cd47e7b92bd';
        var q = 'cryptocurrency';
        if (currentLanguage === 'es') {
            q = 'criptomonedas';
        }
        var fetchNews = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, data, seenUrls_1, seenTitles_1, uniqueNews, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, fetch("https://gnews.io/api/v4/search?q=".concat(q, "&token=").concat(cryptoApiKey, "&lang=").concat(currentLanguage))];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        seenUrls_1 = new Set();
                        seenTitles_1 = new Set();
                        uniqueNews = data.articles.filter(function (article) {
                            var isDuplicate = seenUrls_1.has(article.url) || seenTitles_1.has(article.title.toLowerCase());
                            seenUrls_1.add(article.url);
                            seenTitles_1.add(article.title.toLowerCase());
                            return !isDuplicate;
                        });
                        setNews(uniqueNews);
                        setLoading(false);
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.error('Error fetching news:', error_1);
                        setLoading(false);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        fetchNews();
    }, [currentLanguage]);
    if (loading) {
        return (React.createElement(Box, { display: "flex", justifyContent: "center", alignItems: "center", height: "300px" },
            React.createElement(CircularProgress, null)));
    }
    return (React.createElement("div", { style: { padding: '10px', backgroundColor: '#f9f9f9' } },
        React.createElement(Typography, { variant: "h4", gutterBottom: true, sx: {
                textAlign: 'center',
                fontWeight: 'bold',
                marginBottom: '20px',
                color: '#1e88e5',
            } }, translate('news.cryptoTitle')),
        React.createElement(Grid, { container: true, spacing: 3 }, news.map(function (article, index) { return (React.createElement(React.Fragment, { key: index },
            React.createElement(Grid, { item: true, xs: 12, sm: 6, md: 4 },
                React.createElement(Card, { sx: {
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        borderRadius: '12px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                        '&:hover': {
                            transform: 'translateY(-5px)',
                            boxShadow: '0 6px 15px rgba(0, 0, 0, 0.2)',
                        },
                    } },
                    article.image && (React.createElement(CardMedia, { component: "img", height: "180", image: article.image, alt: article.title, sx: { borderRadius: '12px 12px 0 0', objectFit: 'cover' } })),
                    React.createElement(CardContent, { sx: {
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        } },
                        React.createElement(Link, { href: article.url, target: "_blank", rel: "noopener noreferrer", underline: "hover", sx: {
                                fontWeight: 'bold',
                                fontSize: '1.2rem',
                                color: '#1565c0',
                                marginBottom: '10px',
                                transition: 'color 0.2s ease',
                                '&:hover': { color: '#0d47a1' },
                            } }, article.title),
                        React.createElement(Typography, { variant: "body2", color: "text.secondary", sx: { marginBottom: '10px', lineHeight: '1.6' } }, article.description),
                        React.createElement(Typography, { variant: "caption", color: "text.secondary", sx: { marginTop: 'auto' } },
                            "Fuente: ",
                            article.source.name,
                            " |",
                            ' ',
                            new Date(article.publishedAt).toLocaleString())))),
            index % 3 === 2 && (React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(GoogleAd, { adSlot: "crypto_news_between" }))))); }))));
};
export default CryptoNews;
