import React from 'react';
import App from './App';
import { createRoot } from 'react-dom/client';
import { AppProvider } from './hooks/AppContext';
var container = document.getElementById('root');
if (!container) {
    throw new Error("No se encontró el elemento con id 'root'");
}
var root = createRoot(container);
root.render(React.createElement(AppProvider, null,
    React.createElement(App, null)));
