// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Footer General */
.footer {
  background-color: #000;
  /* Fondo negro */
  color: #fff;
  /* Texto blanco */
  padding: 20px 0;
  font-family: Arial, sans-serif;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.footer-logo-wrapper {
  font-size: 24px;
  margin: 0;
}

.footer-logo {
  max-width: 15%;
}

.footer-links {
  display: flex;
  gap: 15px;
}

.footer-links a {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #00bcd4;
  /* Color de hover */
}

.footer-social {
  display: flex;
  gap: 15px;
}

.footer-social a img {
  width: 24px;
  height: 24px;
  filter: invert(100%);
  /* Hace las imágenes blancas para fondo negro */
  transition: transform 0.3s ease;
}

.footer-social a img:hover {
  transform: scale(1.1);
  /* Efecto hover */
}

.footer-bottom {
  border-top: 1px solid #333;
  padding-top: 10px;
  font-size: 14px;
  color: #aaa;
}`, "",{"version":3,"sources":["webpack://./src/components/footer/Footer.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;EACE,sBAAsB;EACtB,gBAAgB;EAChB,WAAW;EACX,iBAAiB;EACjB,eAAe;EACf,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,8BAA8B;EAC9B,SAAS;EACT,iBAAiB;EACjB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,SAAS;AACX;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,eAAe;EACf,2BAA2B;AAC7B;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,oBAAoB;EACpB,+CAA+C;EAC/C,+BAA+B;AACjC;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,0BAA0B;EAC1B,iBAAiB;EACjB,eAAe;EACf,WAAW;AACb","sourcesContent":["/* Footer General */\n.footer {\n  background-color: #000;\n  /* Fondo negro */\n  color: #fff;\n  /* Texto blanco */\n  padding: 20px 0;\n  font-family: Arial, sans-serif;\n}\n\n.footer-content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  gap: 20px;\n  max-width: 1200px;\n  margin: 0 auto;\n  text-align: center;\n}\n\n.footer-logo-wrapper {\n  font-size: 24px;\n  margin: 0;\n}\n\n.footer-logo {\n  max-width: 15%;\n}\n\n.footer-links {\n  display: flex;\n  gap: 15px;\n}\n\n.footer-links a {\n  text-decoration: none;\n  color: #fff;\n  font-size: 16px;\n  transition: color 0.3s ease;\n}\n\n.footer-links a:hover {\n  color: #00bcd4;\n  /* Color de hover */\n}\n\n.footer-social {\n  display: flex;\n  gap: 15px;\n}\n\n.footer-social a img {\n  width: 24px;\n  height: 24px;\n  filter: invert(100%);\n  /* Hace las imágenes blancas para fondo negro */\n  transition: transform 0.3s ease;\n}\n\n.footer-social a img:hover {\n  transform: scale(1.1);\n  /* Efecto hover */\n}\n\n.footer-bottom {\n  border-top: 1px solid #333;\n  padding-top: 10px;\n  font-size: 14px;\n  color: #aaa;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
