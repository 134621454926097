// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skill-img {
  width: 80px;
  /* Tamaño estándar */
  height: auto;
}

.skills-title {
  margin-bottom: -2% !important;
  font-size: xx-large !important;
}

@media (min-width: 768px) {
  .skill-img {
    width: 180px;
    /* Aumenta el tamaño en pantallas medianas */
  }
  .skills-title {
    font-size: xxx-large !important;
  }
}

@media (min-width: 1200px) {
  .skill-img {
    width: 200px;
    /* Tamaño más grande para pantallas grandes */
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/home/skills/Skills.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,oBAAoB;EACpB,YAAY;AACd;;AAEA;EACE,6BAA6B;EAC7B,8BAA8B;AAChC;;AAEA;EACE;IACE,YAAY;IACZ,4CAA4C;EAC9C;EACA;IACE,+BAA+B;EACjC;AACF;;AAEA;EACE;IACE,YAAY;IACZ,6CAA6C;EAC/C;AACF","sourcesContent":[".skill-img {\n  width: 80px;\n  /* Tamaño estándar */\n  height: auto;\n}\n\n.skills-title {\n  margin-bottom: -2% !important;\n  font-size: xx-large !important;\n}\n\n@media (min-width: 768px) {\n  .skill-img {\n    width: 180px;\n    /* Aumenta el tamaño en pantallas medianas */\n  }\n  .skills-title {\n    font-size: xxx-large !important;\n  }\n}\n\n@media (min-width: 1200px) {\n  .skill-img {\n    width: 200px;\n    /* Tamaño más grande para pantallas grandes */\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
