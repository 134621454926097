import React, { createContext, useContext, useState, useEffect } from 'react';
import enLang from '../components/navBar/lang/en.json';
import esLang from '../components/navBar/lang/es.json';
import frLang from '../components/navBar/lang/fr.json';
var languageFiles = {
    en: enLang,
    es: esLang,
    fr: frLang,
};
var AppContext = createContext(undefined);
export var AppProvider = function (_a) {
    var children = _a.children;
    var pathSegments = window.location.pathname.split('/');
    var lang = pathSegments[1] || 'en';
    var _b = useState(lang), currentLanguage = _b[0], setCurrentLanguage = _b[1];
    var languages = useState(languageFiles)[0];
    var _c = useState(''), currentView = _c[0], setCurrentView = _c[1];
    useEffect(function () {
        var syncValuesFromUrl = function () {
            var pathSegments = window.location.pathname.split('/');
            var lang = pathSegments[1];
            var view = pathSegments[2];
            changeLanguage(lang || 'en');
            changeView(view || '');
        };
        syncValuesFromUrl();
        window.addEventListener('popstate', syncValuesFromUrl);
        // Limpiar el evento al desmontar
        return function () {
            window.removeEventListener('popstate', syncValuesFromUrl);
        };
    }, []);
    var changeLanguage = function (lang) {
        if (languages[lang]) {
            setCurrentLanguage(lang);
            var newUrl = "/".concat(lang, "/").concat(currentView);
            window.history.pushState({}, '', newUrl);
        }
        else {
            console.error("Language ".concat(lang, " not loaded"));
        }
    };
    var changeView = function (view) {
        setCurrentView(view);
        var newUrl = "/".concat(currentLanguage, "/").concat(view);
        window.history.pushState({}, '', newUrl);
    };
    var translate = function (key) {
        var _a;
        return ((_a = languages[currentLanguage]) === null || _a === void 0 ? void 0 : _a[key]) || key;
    };
    return (React.createElement(AppContext.Provider, { value: { currentLanguage: currentLanguage, changeLanguage: changeLanguage, translate: translate, currentView: currentView, changeView: changeView } }, children));
};
// Custom hook to use the AppContext
export var useApp = function () {
    var context = useContext(AppContext);
    if (!context) {
        throw new Error('useApp must be used within a AppProvider');
    }
    return context;
};
